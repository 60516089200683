import { Stack, Typography } from '@mui/material'
import { Page } from 'components/Layouts'
import { format } from 'date-fns'
import { ja } from 'date-fns/locale'
import { formatISODate, safeParseISO, yearMonthFormat } from 'lib/utils'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useParams, useSearchParams } from 'react-router-dom'
import { ChartReport, UseCaseTable, UserVoiceType, UseVoiceReport } from './components'
import { RightHeader } from './components/RightHeader'

export type CompanyMonthlyReportType = {
  company?: App.Models.Company
  report?: App.Models.CompanyReportQualitative
}

const CompanyMonthlyReport = () => {
  const params = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  const [date, setDate] = useState<Date | null>(safeParseISO(searchParams.get('date') as string))
  const { data } = useQuery<CompanyMonthlyReportType>([
    `company-monthly-report/${params?.id}?month_year=${formatISODate(date as Date)}`
  ])

  const [displayFlg, setDisplayFlg] = useState<boolean | null>(true)

  const handleDisplay = (currentTabNumber: number) => {
    // only display when active tab is "総括レポート"
    setDisplayFlg(currentTabNumber === 1)
  }

  useEffect(() => {
    if (date) {
      setSearchParams({ date: formatISODate(date) })
    }
  }, [date, setSearchParams])

  return (
    <Page
      title={
        <Stack>
          <Typography variant="h4">
            {format(date as Date, yearMonthFormat, {
              locale: ja
            })}
          </Typography>
          <Typography variant="h4">{data?.company?.name}様 月次レポート</Typography>
        </Stack>
      }
      leftHeader={<RightHeader date={date} data={data} onChange={(e) => setDate(e)} />}
      elevation={0}
    >
      <Stack mb={4}>
        <Typography variant="h6" ml={1}>
          総括（利用傾向など）
        </Typography>

        <Typography component="div" sx={{ px: 1, '& img': { maxWidth: '100%', height: 'auto' } }}>
          {data?.report?.summary && (
            <div dangerouslySetInnerHTML={{ __html: data.report.summary }} />
          )}
        </Typography>
      </Stack>

      <ChartReport
        date={date as Date}
        comId={Number(params?.id)}
        chartTitle={data?.company?.report_title}
        handleDisplay={handleDisplay}
      />

      {displayFlg && !!data?.report?.last_measure && (
        <Stack mb={4}>
          <Typography variant="h6" ml={1}>
            前回の施策・結果
          </Typography>
          <Typography component="div" sx={{ px: 1, '& img': { maxWidth: '100%', height: 'auto' } }}>
            {data?.report?.last_measure && (
              <div dangerouslySetInnerHTML={{ __html: data.report.last_measure }} />
            )}
          </Typography>
        </Stack>
      )}

      {displayFlg && !!data?.report?.next_measure && (
        <Stack mb={4}>
          <Typography variant="h6" ml={1}>
            今後の施策・取り組み
          </Typography>
          <Typography component="div" sx={{ px: 1, '& img': { maxWidth: '100%', height: 'auto' } }}>
            {data?.report?.next_measure && (
              <div dangerouslySetInnerHTML={{ __html: data.report.next_measure }} />
            )}
          </Typography>
        </Stack>
      )}

      {displayFlg && !!data?.report?.free && (
        <Stack mb={4}>
          <Typography variant="h6" ml={1}>
            その他
          </Typography>
          <Typography component="div" sx={{ px: 1, '& img': { maxWidth: '100%', height: 'auto' } }}>
            {data?.report?.free && <div dangerouslySetInnerHTML={{ __html: data.report.free }} />}
          </Typography>
        </Stack>
      )}

      {displayFlg && <UseVoiceReport data={data?.report?.user_voices as UserVoiceType[]} />}

      {displayFlg && <UseCaseTable data={data?.report?.use_cases} />}
    </Page>
  )
}

export { CompanyMonthlyReport }
