import { Button, ListItemProps, ListItemText, Stack, styled, SvgIcon } from '@mui/material'
import { ReactComponent as Admin } from 'assets/svg/admin.svg'
import { ReactComponent as Bell } from 'assets/svg/bell.svg'
import { ReactComponent as Building } from 'assets/svg/building.svg'
import { ReactComponent as Calendar } from 'assets/svg/calendar.svg'
import { ReactComponent as Cast } from 'assets/svg/cast.svg'
import { ReactComponent as Chart } from 'assets/svg/chart.svg'
import { ReactComponent as Group } from 'assets/svg/group.svg'
import { ReactComponent as Image } from 'assets/svg/image.svg'
import { ReactComponent as Paper } from 'assets/svg/paper.svg'
import { ReactComponent as Today } from 'assets/svg/today.svg'
import { ReactComponent as Tree } from 'assets/svg/tree.svg'
import { ReactComponent as Video } from 'assets/svg/video.svg'
import { ReactComponent as Wallet } from 'assets/svg/wallet.svg'
import { ListItemLink } from 'components/ListItemLink'
import { NestedMenu, NestMenuType } from 'components/NestedMenuList'
import { PERM } from 'lib/constants'
import { useAuth } from 'lib/hooks'
import { ReactElement, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { DrawerLeft } from './Drawer'

const ButtonLogout = styled(Button)(({ theme }) => ({
  position: 'relative',
  marginRight: theme.spacing(2),
  marginBottom: theme.spacing(2),
  marginTop: 'auto',
  marginLeft: 'auto',
  color: theme.palette.grey[400]
}))

export type MenuRSS = {
  icon?: ReactElement
  permissions?: PERM[]
}

export const menus: NestMenuType<MenuRSS>[] = [
  {
    label: '企業管理',
    path: '/company',
    key: '/company',
    resource: {
      icon: <SvgIcon viewBox="0 0 16 18" component={Building} />,
      permissions: [PERM.ADMIN]
    }
  },
  {
    label: 'グループ管理',
    path: '/group',
    key: '/group',
    resource: {
      icon: <SvgIcon viewBox="0 0 19 16" component={Tree} />,
      permissions: [PERM.ADMIN, PERM.CONTENT_MANAGER]
    }
  },
  {
    label: 'ユーザー管理',
    path: '/user',
    key: '/user',
    resource: {
      icon: <SvgIcon viewBox="0 0 20 15" component={Group} />,
      permissions: [PERM.ADMIN]
    }
  },
  {
    label: '月次レポート管理',
    path: '/monthly-report',
    key: '/monthly-report',
    resource: {
      icon: <SvgIcon viewBox="0 0 19 14" component={Chart} />,
      permissions: [PERM.ADMIN, PERM.CONTENT_MANAGER]
    },
    subMenus: [
      {
        label: '総括レポート管理',
        path: '/summary-report',
        key: '/summary-report',
        resource: {
          permissions: [PERM.ADMIN, PERM.CONTENT_MANAGER]
        }
      },
      {
        label: '定量データ設定',
        path: '/quantitative-report',
        key: '/quantitative-report',
        resource: {
          permissions: [PERM.ADMIN]
        }
      }
    ],
    isToggleOutside: true
  },
  {
    label: 'C向けレポート管理',
    path: '/customer-report',
    key: '/customer-report',
    resource: {
      icon: <SvgIcon viewBox="0 0 16 17" component={Chart} />,
      permissions: [PERM.ADMIN, PERM.CONTENT_MANAGER, PERM.CONCIERGE, PERM.RESEARCH]
    }
  },
  {
    label: 'イベント管理',
    path: '/event',
    key: '/event',
    resource: {
      icon: <SvgIcon viewBox="0 0 16 17" component={Calendar} />,
      permissions: [PERM.ADMIN, PERM.CONTENT_MANAGER, PERM.CONCIERGE]
    }
  },
  {
    label: 'コンテンツ管理',
    path: '/content',
    key: '/content',
    resource: {
      icon: <SvgIcon viewBox="0 0 16 16" component={Video} />,
      permissions: [PERM.ADMIN, PERM.CONTENT_MANAGER]
    }
  },
  {
    label: '講師管理',
    path: '/instructor',
    key: '/instructor',
    resource: {
      icon: <SvgIcon viewBox="0 0 19 15" component={Cast} />,
      permissions: [PERM.ADMIN, PERM.CONTENT_MANAGER, PERM.CONCIERGE]
    }
  },
  {
    label: '講師支払い報酬管理',
    path: '/instructor/monthly-reward',
    key: '/instructor/monthly-reward',
    divider: true,
    resource: {
      icon: <SvgIcon viewBox="0 0 16 14" component={Wallet} />,
      permissions: [PERM.ADMIN, PERM.CONTENT_MANAGER]
    }
  },
  {
    label: '稼働時間管理',
    path: '/working-record',
    key: '/working-record',
    resource: {
      icon: <SvgIcon viewBox="0 0 15 18" component={Today} />,
      permissions: [PERM.ADMIN, PERM.CONTENT_MANAGER, PERM.CONCIERGE, PERM.RESEARCH]
    }
  },
  {
    label: 'ニュース管理',
    path: '/news',
    key: '/news',
    resource: {
      icon: <SvgIcon viewBox="0 0 17 12" component={Paper} />,
      permissions: [PERM.ADMIN, PERM.CONTENT_MANAGER]
    }
  },
  {
    label: 'コンシェルジュ管理',
    path: '/concierge',
    key: '/concierge',
    resource: {
      icon: <SvgIcon viewBox="0 0 16 13" component={Bell} />,
      permissions: [PERM.ADMIN]
    }
  },
  {
    label: '管理者管理',
    path: '/admin-user',
    key: '/admin-user',
    resource: {
      icon: <SvgIcon viewBox="0 0 17 15" component={Admin} />,
      permissions: [PERM.ADMIN, PERM.CONTENT_MANAGER, PERM.CONCIERGE]
    }
  },
  {
    label: 'キービジュアル変更',
    path: '/key-visual',
    key: '/key-visual',
    resource: {
      icon: <SvgIcon viewBox="0 0 17 12" component={Image} />,
      permissions: [PERM.ADMIN]
    }
  }
]

const Sidebar: React.VFC = () => {
  const { logout, auth, user } = useAuth()

  const validItem = (item: NestMenuType<MenuRSS>) =>
    item.resource?.permissions?.includes(user?.permission_id as number)

  const filterMenus = (
    menus: NestMenuType<MenuRSS>[],
    fn: (item: NestMenuType<MenuRSS>) => boolean | undefined
  ) => {
    return menus.reduce((m: NestMenuType<MenuRSS>[], i) => {
      const sub = filterMenus(i.subMenus || [], fn) || []

      if (fn(i) || sub.length) {
        m.push(Object.assign({}, i, { subMenus: sub.length && sub }))
      }

      return m
    }, [])
  }

  const navigate = useNavigate()
  const handleLogout = async () => {
    try {
      if (auth) {
        await logout()
        navigate('/login')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const renderItem = useCallback((item: NestMenuType<MenuRSS>, listItemProps: ListItemProps) => {
    const { children } = listItemProps
    const hasIcon = !!item?.resource?.icon

    return (
      <ListItemLink
        sx={{ justifyContent: 'space-between', pr: 0 }}
        to={item.path as string}
        {...listItemProps}
        onClick={(e) => e.stopPropagation()}
      >
        <Stack direction="row" alignItems="center">
          {hasIcon && item.resource?.icon}
          <ListItemText primary={item.label} sx={{ ml: 2 }} />
        </Stack>
        {children}
      </ListItemLink>
    )
  }, [])

  return (
    <DrawerLeft>
      <NestedMenu menus={filterMenus(menus, validItem)} renderItem={renderItem} />
      <ButtonLogout onClick={handleLogout}>ログアウト</ButtonLogout>
    </DrawerLeft>
  )
}

export default Sidebar
